import { graphql } from 'gatsby'
import React, { useState } from 'react'
import AllBlogPosts from '../../components/blog/all_blog_posts'
import Container from '../../components/elements/container'
import { LanguageSwitcher } from '../../components/lang_switcher'
import Layout from '../../components/layout'

export const query = graphql`
  query pagesBlogQuery($skip: Int!, $limit: Int!, $lang: String) {
    allPrismicBlogPost(
      sort: { fields: last_publication_date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          lang
          prismicId
          type
          data {
            ...author
            ...post
          }
          uid
          alternate_languages {
            id
            type
            lang
            uid
          }
          last_publication_date
        }
      }
    }
  }
`

const PageBlog = ({ data, pageContext }: any) => {
  const { blogHome, localePath, lang, altLang } = pageContext

  const newsletterData = {
    title: blogHome[0].node.data.newsletter_title,
    input_placeholder: blogHome[0].node.data.newsletter_input_placeholder,
    button_label: blogHome[0].node.data.newsletter_button_label,
  }

  const postsList = data.allPrismicBlogPost.edges

  const formatDatePostsList = postsList.map((array: any) => ({
    node: {
      alternate_languages: array.node.alternate_languages,
      data: array.node.data,
      lang: array.node.lang,
      last_publication_date: new Date(array.node.last_publication_date).toDateString(),
      prismicId: array.node.prismicId,
      type: array.node.type,
      uid: array.node.uid,
    },
  }))

  const [blogPosts, setBlogPosts] = useState(formatDatePostsList)

  return (
    <Layout
      helmetTitle={blogHome[0].node.data.title[0].text}
      helmetDescription={blogHome[0].node.data.meta_description}
      helmetImage={blogHome[0].node.data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <Container>
        <LanguageSwitcher lang={lang} localePath={localePath} altLang={altLang} />
      </Container>
      <AllBlogPosts
        posts={blogPosts}
        newsletterData={newsletterData}
        pageContext={pageContext}
        pagination={true}
        lang={lang}
      />
    </Layout>
  )
}

export default PageBlog
